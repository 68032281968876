import React, { Component } from 'react';
import Layout from './Layout';
import { Form, Modal, Input, Radio, Checkbox, message, Breadcrumb, Button, Spin, Popconfirm, InputNumber, Popover } from 'antd';
import { withRouter, Link, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStiches } from '../redux/actions/stitches';
import { addChart } from '../redux/actions/addChart';
import { SwatchesPicker } from 'react-color';
import FormItem from 'antd/lib/form/FormItem';
import { getChart } from '../redux/actions/getChart';
import { getColors } from '../redux/actions/getColors';
import { adminRoutes } from '../helpers/adminRoutes';
import Row from './Row'
import ElementalStitch from './ElementalStitch';
import CompoundStitch from './CompoundStitch';
import Border from './Border';
import Marker from './Marker';
import Colors from './Colors';
import Important from './Important';
import { Beforeunload } from 'react-beforeunload';

import { createGridArray } from '../redux/actions/createGridArray';
import { deleteChart } from '../redux/actions/deleteChart';
import TextArea from 'antd/lib/input/TextArea';



class Grid extends Component {

    constructor(props) {
        super(props)
        this.state = {
            editDatass: null,
            numbersValue: null,
            checkVal: false,
            showMenuforCol: false,
            menuIndex: null,
            showMenu: false,
            numberValue: null,
            editInstructionData: [],
            stichNumberChecked: false,
            rowsindex: null,
            indexAddRow: false,
            cellsindex: null,
            loadStitch: false,
            instructions: null,
            rowUpdated: false,
            defaulState: false,
            editableSpin: false,
            isScrolling: false,
            displayTextBox: false,
            displayAllTextBox: false,
            displayColorPicker: false,
            instructionGroup: null,
            multiLineData: [],
            ChangeColorPicker: false,
            openTextArea: false,
            tableCheck: false,
            visible: false,
            cellReuired: false,
            rowsReuired: false,
            radioRequired: false,
            datas: null,
            undoRedoStack: [],
            redoStack: [],
            radioValue: null,
            checkboxValue: null,
            tableValue: [],
            NoteVal: [],
            tableRow: null,
            tableCol: null,
            patternName: null,
            stitchNum: null,
            background: '#fff',
            color: [],
            ongrid: true,
            divWidth: 0,
            divHeight: 0,
            // colorReuired: false,
            IsNumber: "",
            stitchLst: null,
            importantNoteIndex: [],
            stitchNumberIndex: [],
            stitchValue: null,
            importantValue: null,
            instructionValues: [],
            boderValue: null,
            markValue: null,
            colorValue: null,
            indexVAl: null,
            edit: false,
            type: null,
            redostack: [],
            editDataValues: null,
            patternId: null,
            sizeId: null,
            sectionId: null,
            changedRowIndex: null,
            colorIndex: null,
            colorIndexValue: null,
            importantModal: false,
            numberModal: false,
            importantValues: null,
            loader: false
        }
        this.refImportant = React.createRef()
        this.refNumber = React.createRef()

    }


    handlemultilineInstruction = (e) => {
        let { instructionValues } = this.state
        this.props.form.validateFields((err, values) => {
            this.setState({ instructions: values.instructionData })
            var splitted = values.instructionData.split(/(\d{0,4}):/)
            const arr = [];
            splitted.map((v, i, a) =>
                v && !isNaN(v) && v !== "" && i < splitted.length ?
                    (
                        arr.push({ index: instructionValues.length - v, text: a[i + 1] }))
                    : ""

            )
            const arrayList1 = arr;

            const arrayList2 = [...instructionValues];

            arrayList1.forEach((data) => {
                if (data && data.text) {
                    arrayList2[data.index].text = data.text
                }
            })


            this.setState({ openTextArea: false, instructionGroup: values.instructionData, multiInstruction: arr, instructionValues: [...arrayList2], editInstructionData: [...arrayList2] })

        })
    }

    handleNoteDelete = (e) => {
        e.preventDefault();
        let { tableValue, importantNoteIndex } = this.state
        const tempArr = [...tableValue];

        let row = importantNoteIndex[0]
        let col = importantNoteIndex[1]

        tempArr[row][col][`important`] = false
        this.props.form.validateFields((err, values) => {
            let temp = tableValue;
            temp[row][col].note = ""

            this.setState({ NoteVal: "", tableValue: tempArr, importantNoteIndex: [row, col], importantModal: false })

            this.props.form.resetFields();

        })


    }


    handleNotecross = (e) => {
        e.preventDefault();
        let { tableValue, importantNoteIndex } = this.state
        const tempArr = [...tableValue];

        let row = importantNoteIndex[0]
        let col = importantNoteIndex[1]
        let temp = tableValue;

        if (temp[row][col].note !== undefined && temp[row][col].note !== '') {
            this.setState({ importantModal: false })
        } else {
            tempArr[row][col][`important`] = false
            this.props.form.validateFields((err, values) => {
                let temp = tableValue;
                temp[row][col].note = ""
                this.setState({ NoteVal: "", tableValue: tempArr, importantNoteIndex: [row, col], importantModal: false })

                this.props.form.resetFields();

            })
        }



    }

    handleChartDelete = (e) => {

        e.preventDefault();
        let { location, dispatch, history } = this.props;
        let path = location.pathname.split('/')

        let patternId = path[2];
        let sizeId = path[3];
        let sectionId = path[4];
        dispatch(deleteChart(sectionId)).then(res => {
            // if (res && res.status === 200) {
            this.props.history.push(adminRoutes.GRID + '/' + patternId + '/' + sizeId + '/' + sectionId);
            window.location.reload();
            // }
        })


    }

    hideShow = (e) => {
        e.preventDefault();
        let x = document.getElementsByClassName("hideShow");
        if (x.length > 0) {
            document.body.classList.remove("hideShow");
        } else {
            document.body.classList.add("hideShow");
        }
    }

    eStitchDataHandler = (e, val) => {
        e.preventDefault();
        let { checkboxValue } = this.state;
        let tempval = { ...checkboxValue }
        const newStitch = val.stitchData ? val.stitchData : "";
        tempval.stitchData = newStitch;
        this.setState({ stitchValue: newStitch, checkboxValue: tempval })

    }

    eBorderHandler = (e, val) => {
        e.preventDefault()
        let { checkboxValue } = this.state;
        let tempval = { ...checkboxValue }
        const newBorder = val.boderValue ? val.boderValue : "";
        tempval.border = newBorder;
        this.setState({ boderValue: newBorder, checkboxValue: tempval });
    }

    eMarkHandler = (e, val) => {
        e.preventDefault()
        let { checkboxValue } = this.state;
        let tempval = { ...checkboxValue }
        const newBorder = val.markValue ? val.markValue : "";
        tempval.border = newBorder;
        this.setState({ markValue: newBorder, checkboxValue: tempval });
    }



    initTableValues = (cellCount) => {
        const cells = Array.from(Array(+cellCount).keys())
        return [...(cells.map(c => ({
            _id: '',
            color: '',
            border: '',
            important: false
        })))]
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.refImportant.current) {
            this.refImportant.current.focus()
        }
        if (this.refNumber.current) {
            this.refNumber.current.focus()
        }

        const newState = {};


        let { location, dispatch } = this.props;
        let path = location.pathname.split('/')

        let patternId = path[2];
        let sizeId = path[3];
        let sectionId = path[4];

        newState[`patternId`] = patternId
        newState[`sectionId`] = sectionId
        newState[`sizeId`] = sizeId

        var a = document.getElementsByClassName("gridRightGridInner")
        var b = document.getElementsByClassName("grid-table")
        if (location && prevProps.location !== location) {
            dispatch(getStiches()).then(response => {
                this.setState({ stitchLst: response.data })
                dispatch(getChart(patternId, sizeId, sectionId)).then(resp => {
                    this.setState({ editInstructionData: resp.data.data && resp.data.data.instruction })

                    if (resp.data && resp.data.patternData && resp.data.sizeData && resp.data.sectionData) {
                        newState[`patternName`] = resp.data.patternData && resp.data.patternData.data && resp.data.patternData.data;
                        newState[`sizeName`] = resp.data.sizeData && resp.data.sizeData.data && resp.data.sizeData.data;
                        newState[`sectionName`] = resp.data.sectionData && resp.data.sectionData.data && resp.data.sectionData.data;

                    }
                    if (resp.data && resp.data.data) {
                        dispatch(getColors(patternId)).then(response => {

                            var color = response && response.data && response.data.data && response.data.data.data && response.data.data.data.colors;

                            newState[`color`] = color

                        });
                        if ((resp.data && resp.data.data && resp.data.data.rows) % 2 === 0) {
                            newState[`IsNumber`] = "even"
                        }
                        else {
                            newState[`IsNumber`] = "odd"

                        }
                        if ((resp.data && resp.data.data && resp.data.data.construction) === "Worked Flat") {
                            newState[`radioValue`] = "Worked Flat"
                        }
                        else {
                            newState[`radioValue`] = "In the Round"

                        }

                        newState[`tableValue`] = resp.data.data.tableValue
                        newState[`editDatass`] = resp.data.data.tableValue
                        newState[`datas`] = resp.data.data
                        newState[`visible`] = false
                        newState[`edit`] = true

                    }
                    else {
                        newState[`visible`] = true
                    }
                    // this.setState({
                    //     checkVal: false,
                    //     instructions: null,
                    //     rowUpdated: false,
                    //     defaulState: false,
                    //     editableSpin: false,
                    //     isScrolling: false,
                    //     displayTextBox: false,
                    //     displayAllTextBox: false,
                    //     displayColorPicker: false,
                    //     instructionGroup: null,
                    //     multiLineData: [],
                    //     ChangeColorPicker: false,
                    //     openTextArea: false,
                    //     tableCheck: false,
                    //     visible: false,
                    //     cellReuired: false,
                    //     rowsReuired: false,
                    //     radioRequired: false,
                    //     datas: null,
                    //     undoRedoStack: [],
                    //     redoStack: [],
                    //     radioValue: null,
                    //     checkboxValue: null,
                    //     tableValue: [],
                    //     NoteVal: [],
                    //     tableRow: null,
                    //     tableCol: null,
                    //     patternName: null,
                    //     background: '#fff',
                    //     // color: [],
                    //     ongrid: true,
                    //     divWidth: 0,
                    //     divHeight: 0,
                    //     // colorReuired: false,
                    //     IsNumber: "",
                    //     importantNoteIndex: [],
                    //     stitchValue: null,
                    //     importantValue: null,
                    //     instructionValues: [],
                    //     boderValue: null,
                    //     colorValue: null,
                    //     indexVAl: null,
                    //     edit: false,
                    //     type: null,
                    //     redostack: [],
                    //     editDataValues: null,
                    //     patternId: null,
                    //     sizeId: null,
                    //     sectionId: null,
                    //     changedRowIndex: null,
                    //     colorIndex: null,
                    //     colorIndexValue: null,
                    //     importantModal: false,
                    //     importantValues: null,
                    //     loader: false
                    // })
                    // this.setState(newState)
                })
            });


            dispatch(getColors(patternId)).then(response => {
                this.setState({ color: response && response.data && response.data.data && response.data.data.data && response.data.data.data.colors })
            });
        }

        if (a.length > 0 && b.length > 0) {
            var divWidth = a[0].clientWidth;
            var divHeight = b[0].clientHeight;
            newState[`divWidth`] = divWidth;
            newState[`divHeight`] = divHeight;

        }


        let { gridArrayLoading, gridArrayData, editData } = this.props;


        const cond1 = editData && prevProps.editData !== editData;
        const cond2 = !gridArrayLoading && gridArrayData && prevProps.gridArrayData !== gridArrayData;
        if (cond1) {
            if (editData && editData.data && editData.data.color) {
                newState[`instructionValues`] = editData.data.instruction
                setTimeout(() => {
                    this.scrollToXY('dragscroll', editData.data.rows * 41, editData.data.cells * 41);
                }, 1000);
            }

        }

        if (cond2) {
            newState[`tableValue`] = gridArrayData
            newState[`loader`] = false
            setTimeout(() => {
                this.scrollToXY('dragscroll', gridArrayData.length * 41, gridArrayData[0].length * 41);
            }, 1000);

        }


        if (cond1 || cond2) {

            this.setState(newState)
        }

        // console.log('this.state.datas => ', this.state.datas.rows);
        // console.log('gridArrayData.length => ', gridArrayData.length);

        // if (gridArrayData.length === (this.state.datas && this.state.datas.rows)) {
        //     this.handleGridSubmit()
        // }

    }

    componentDidMount() {

        const newState = {};
        let { location, dispatch } = this.props;
        let path = location.pathname.split('/')

        let patternId = path[2];
        let sizeId = path[3];
        let sectionId = path[4];

        newState[`patternId`] = patternId
        newState[`sectionId`] = sectionId
        newState[`sizeId`] = sizeId
        dispatch(getStiches()).then(response => {
            this.setState({ stitchLst: response.data });
            dispatch(getChart(patternId, sizeId, sectionId)).then(resp => {

                this.setState({ editInstructionData: resp.data.data && resp.data.data.instruction })
                if (resp.data && resp.data.patternData && resp.data.sizeData && resp.data.sectionData) {
                    newState[`patternName`] = resp.data.patternData && resp.data.patternData.data && resp.data.patternData.data;
                    newState[`sizeName`] = resp.data.sizeData && resp.data.sizeData.data && resp.data.sizeData.data;
                    newState[`sectionName`] = resp.data.sectionData && resp.data.sectionData.data && resp.data.sectionData.data;

                }
                if (resp.data && resp.data.data) {
                    dispatch(getColors(patternId)).then(response => {

                        var color = response && response.data && response.data.data && response.data.data.data && response.data.data.data.colors;
                        newState[`color`] = color

                    });
                    if ((resp.data && resp.data.data && resp.data.data.rows) % 2 === 0) {
                        newState[`IsNumber`] = "even"
                    }
                    else {
                        newState[`IsNumber`] = "odd"

                    }
                    if ((resp.data && resp.data.data && resp.data.data.construction) === "Worked Flat") {
                        newState[`radioValue`] = "Worked Flat"
                    }
                    else {
                        newState[`radioValue`] = "In the Round"

                    }

                    newState[`tableValue`] = resp.data.data.tableValue
                    newState[`datas`] = resp.data.data
                    newState[`editDatass`] = resp.data.data.tableValue

                    newState[`visible`] = false
                    newState[`edit`] = true

                }
                else {
                    newState[`visible`] = true
                }
                this.setState(newState)
            })
        })

        dispatch(getColors(patternId)).then(response => {

            this.setState({ color: response && response.data && response.data.data && response.data.data.data && response.data.data.data.colors })

        });

    }


    handleResetCheckboxValue = (e) => {
        e.preventDefault()
        this.setState({ boderValue: false, border: null, markValue: false, stichNumberChecked: false, colorIndex: false, stitchNumberIndex: null, numberValue: null, colorValue: null, importantValue: false, stitchValue: false, stitchData: null, checkboxValue: null })
        // this.resetImportant()
    }

    handleExit = () => {
        let { location } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sizeId = path[3];
        this.props.history.push(adminRoutes.SECTIONLIST + '/' + patternId + '/' + sizeId);
    }

    eColorHandler = (e) => {
        let Datas = e.color;
        this.setState({ color: Datas })
    };


    handleOk = (e) => {
        e.preventDefault();
        this.setState({ visible: false })
    }

    onChange = e => {
        e.preventDefault();
        this.setState({
            radioValue: e.target.value,
        });
    };




    zoomIn = (e) => {
        e.preventDefault();
        var Page = document.getElementById('Body');
        var zoom = `${parseInt(Page.style.zoom || 100) + 10}%`
        Page.style.zoom = zoom;
        return false;
    }

    zoomOut = (e) => {
        e.preventDefault();
        var Page = document.getElementById('Body');
        var zoom = `${parseInt(Page.style.zoom || 100) - 10}%`
        Page.style.zoom = zoom;
        return false;
    }

    handleCancel = (e) => {
        e.preventDefault();
        let { radioValue } = this.state;
        const newState = [];
        this.props.form.validateFields((err, values) => {

            if (this.state.tableValue.length === 0) {
                if (values.cells === null || radioValue === null || values.rows === null) {
                    newState[`cellReuired`] = true
                    newState[`visible`] = true
                    if (radioValue === null) {
                        newState[`radioRequired`] = true
                        newState[`visible`] = true
                    }
                    if (values.rows === null) {
                        newState[`rowsReuired`] = true
                        newState[`visible`] = true
                    }
                }
                this.setState(newState)
                message.error('Please add row and column');
                this.setState({ visible: true })

            }
            else {
                this.setState({ visible: false })

            }

        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const newState = {}
        let { dispatch, editData, location } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sizeId = path[3];
        let sectionId = path[4]
        let { edit, tableValue, color, instructionValues, radioValue, instructionGroup } = this.state;

        if (edit === true) {
            newState[`checkVal`] = false
            newState[`defaulState`] = true
            this.props.form.validateFields((err, values) => {
                dispatch(addChart(values, tableValue, instructionValues, instructionGroup, patternId, sizeId, sectionId, radioValue))
                dispatch(getChart(patternId, sizeId, sectionId)).then(resp => {

                    if (resp.data && resp.data.data) {
                        this.setState({ editInstructionData: resp.data.data && resp.data.data.instruction, defaulState: true, tableValue: resp.data.data.tableValue, datas: resp.data.data, visible: false, edit: true, editableSpin: true })
                    }

                })
            })

        }

        if (edit !== true) {

            this.props.form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                newState[`instructionValues`] = Array.from({ length: values.rows }, (v, k) => ({ index: k, text: null }))
                newState[`loader`] = true
                newState[`visible`] = false
                newState[`tableValue`] = []


                if (values.rows % 2 === 0 ? values.rows % 2 === 0 : (editData && editData.data && editData.data.rows) % 2 === 0) {
                    newState[`IsNumber`] = "even"
                }
                else {
                    newState[`IsNumber`] = "odd"

                }

                if (values.cells === null || radioValue === null || values.rows === null) {
                    newState[`cellReuired`] = true
                    newState[`visible`] = true
                    if (radioValue === null) {
                        newState[`radioRequired`] = true
                        newState[`visible`] = true
                    }
                    if (values.rows === null) {
                        newState[`rowsReuired`] = true
                        newState[`visible`] = true
                    }
                }
                else {
                    dispatch(createGridArray(values.rows, values.cells))

                    newState[`datas`] = values
                    newState[`visible`] = false
                    newState[`tableRow`] = values.rows
                    newState[`tableCol`] = values.cells

                }

            })
        }

        else {
            this.props.form.validateFields((err, values) => {
                newState[`visible`] = false
                // dispatch(getChart(patternId, sizeId, sectionId)).then(resp => {

                //     if (resp.data && resp.data.data) {
                //         this.setState({ defaulState: true, tableValue: resp.data.data.tableValue, datas: resp.data.data, visible: true, edit: true, editableSpin: true })
                //     }

                // })

            })
        }

        this.setState(newState)

    }


    scrollToXY = (className, x, y) => {
        const gridBox = document.getElementsByClassName(className);

        if (gridBox !== null && this.state.ongrid === true) {
            gridBox.Body.scrollTo(x, y)
            this.setState({ ongrid: false })
        }
    }

    handleGridSubmit = (e) => {
        // e.preventDefault();
        const newState = {}
        this.setState({ checkVal: false, defaulState: true })
        let { tableValue, color, instructionValues, radioValue, instructionGroup } = this.state;
        let { location, dispatch } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sizeId = path[3];
        let sectionId = path[4]

        this.props.form.validateFields((err, values) => {

            dispatch(addChart(values, tableValue, instructionValues, instructionGroup, patternId, sizeId, sectionId, radioValue)).then(response => {
                if (response && response.status === 200) {
                    message.success('Chart saved successfully');
                    // window.location.reload();

                    dispatch(getChart(patternId, sizeId, sectionId)).then(resp => {
                        this.setState({ editInstructionData: resp.data.data && resp.data.data.instruction })

                        if (resp && resp.data && resp.data.data) {

                            newState[`tableValue`] = resp.data.data.tableValue
                            newState[`datas`] = resp.data.data
                            newState[`visible`] = false

                        }
                        else {
                            newState[`visible`] = true
                        }
                    })
                }
            });
        })
        this.setState(newState);
    }

    handleColorChangeComplete = (color, index) => {

        const newState = {};
        let Datas = color.hex;
        let colorData = this.state.color;

        if (typeof index === "number") {

            colorData[index] = Datas
            newState[`color`] = colorData
            newState[`ChangeColorPicker`] = false
        } else {
            colorData.push(Datas);
            newState[`color`] = colorData
        }

        this.setState(newState)

        function compare(a, b) {
            const bandA = b.position
            const bandB = a.position

            let comparison = 0;
            if (bandB > bandA) {
                comparison = 1;
            } else if (bandB < bandA) {
                comparison = -1;
            }
            return comparison;
        }

        this.state.color.sort(compare);
        this.setState({ color: this.state.color })

    };


    handleCheckboxChange = (e, val) => {
        e.preventDefault();
        let { checkboxValue } = this.state;
        let tempval = { ...checkboxValue }
        const newStitch = tempval.stitchData !== val ? val : "";
        tempval.stitchData = newStitch;
        this.setState({ stitchValue: newStitch, checkboxValue: tempval })
    }

    handleColorClick = (e, val, index) => {

        e.preventDefault();

        const newState = {}
        let { checkboxValue } = this.state;
        if (e.target.checked === false) {
            let tempval = {
                ...checkboxValue
            }
            tempval.color = ""
            newState[`colorValue`] = null
            newState[`colorIndex`] = null
            newState[`checkboxValue`] = tempval
        } else {
            let tempval = { ...checkboxValue }
            const newColor = tempval.color !== val ? val : "";
            tempval.color = val.color;
            tempval.colorIndex = val._id;
            newState[`colorValue`] = val.color
            newState[`colorIndex`] = val._id
            newState[`checkboxValue`] = tempval
        }
        this.setState({ ...newState })

    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleBorderClick = (e, val) => {
        e.preventDefault();
        let { checkboxValue } = this.state;
        let tempval = { ...checkboxValue }
        const newBorder = e.target.checked ? val : "";
        tempval.border = newBorder;
        this.setState({ boderValue: newBorder, checkboxValue: tempval });
    }



    handleInstructionData = (e, i) => {
        e.preventDefault();
        var tempArr = this.state.instructionValues;
        let objFound = tempArr.find(a => a.index === i);
        (objFound) ? objFound.text = e.target.value : tempArr.slice(i, 0, { text: e.target.value, index: i });
        this.setState({ instructionValues: tempArr }, () => {
        });
    }


    handleNote = (e, i) => {
        e.preventDefault();
        let { tableValue, importantNoteIndex } = this.state
        let row = importantNoteIndex[0]
        let col = importantNoteIndex[1]
        this.props.form.validateFields((err, values) => {
            let temp = tableValue;
            temp[row][col].note = values.note

            this.setState({ NoteVal: values.note, tableValue: temp, importantModal: false })
            this.props.form.resetFields();

        })


    }



    handleInstructionText = (e, i) => {
        e.preventDefault();
        this.setState({ type: "single", displayTextBox: true, indexVAl: i })
    };

    deleteRow = (e, i, type) => {
        e.preventDefault();
        var temp2 = []
        let { tableValue, datas, instructionValues, editInstructionData, undoRedoStack } = this.state;
        let { editData } = this.props;

        if (editData && editData.data && editData.data.instruction && editData.data.instruction.length > 0) {
            temp2 = [...editInstructionData]

        }
        else {
            temp2 = [...instructionValues]

        }

        var temp = [...tableValue]

        temp2.splice(i, 1)

        var arrayIndex = [];

        for (const instValue of temp2) {
            if (instValue.index < i) {
                var obj = {
                    index: instValue.index,
                    text: instValue.text
                }
                arrayIndex.push(obj)
            }
            if (instValue.index > i) {
                var obj = {
                    index: instValue.index - 1,
                    text: instValue.text
                }
                arrayIndex.push(obj)
            }
        }

        if (datas && datas.construction === "Worked Flat" && datas && datas.rows % 2 === 0 && this.state.indexAddRow === false) {

            if ((i % 2) === 0) {

                temp.splice(i + 1, 1)

            }
            else {
                temp.splice(i - 1, 1)

            }
            this.setState({ indexAddRow: true, checkVal: true })

        }

        else {
            temp.splice(i, 1)
            if (datas && datas.construction === "Worked Flat") {
                this.setState({ indexAddRow: false })

            }

        }
        var copy_value = temp[i]

        if (type === undefined) {
            let stackArr = undoRedoStack

            stackArr.push({ index: [i], value: { ...copy_value }, key: "deleteRow" })
            this.setState({ undoRedoStack: [...stackArr] })
        }
        if (type == "redo") {
            datas.rows = datas.rows + 1;

        }
        else {
            datas.rows = datas.rows - 1;

        }
        this.setState({ instructionValues: [...arrayIndex], editInstructionData: [...arrayIndex], tableValue: temp, datas: datas, rowUpdated: true })
        if (datas.rows % 2 === 0) {

            this.setState({ IsNumber: "even" })
        }
        else {

            this.setState({ IsNumber: "odd" })
        }
        setTimeout(() => {
            this.setState({ rowUpdated: false })
        }, 100)
        this.setState({ showMenu: false, menuIndex: true })
    };

    addRow = async (e, i, type) => {
        e.preventDefault();
        var arrayInstruction = [];
        var temp2 = [];
        let { edit, tableValue, datas, radioValue, instructionValues, editInstructionData, undoRedoStack } = this.state;
        // this.handleGridSubmit(e)
        let { editData } = this.props;

        if (editData && editData.data && editData.data.instruction && editData.data.instruction.length > 0) {
            temp2 = [...editInstructionData]


        }
        else {
            temp2 = [...instructionValues]

        }

        var item = { ...temp2[i] }

        var copy_val = {
            index: item.index,
            text: item.text
        }


        arrayInstruction.push(copy_val)

        var obj = {
            index: item.index + 1,
            text: item.text
        }
        arrayInstruction.push(obj)

        for (const instValue of temp2) {
            if (instValue.index >= item.index + 1) {
                var obj = {
                    index: instValue.index + 1,
                    text: instValue.text
                }
                arrayInstruction.push(obj)
            }
            else if (instValue.index < item.index) {
                var obj = {
                    index: instValue.index,
                    text: instValue.text
                }
                arrayInstruction.push(obj)
            }
        }

        var temp = tableValue


        if (datas && datas.construction === "Worked Flat" && this.state.indexAddRow === true) {

            var copy_value = [...tableValue[i]]

            this.setState({ indexAddRow: false })
        }

        else
            if (datas && datas.construction) {
                if (datas && datas.construction === "Worked Flat") {


                    if (i % 2 === 0) {

                        var copy_value = [...tableValue[i + 1]]
                        this.setState({ indexAddRow: true })
                    }
                    else {

                        var copy_value = [...tableValue[i - 1]]
                        this.setState({ indexAddRow: true })
                    }

                }
                else {
                    var copy_value = [...tableValue[i]]

                }
            }
            else if (radioValue && radioValue === "Worked Flat" && this.state.indexAddRow === true) {

                var copy_value = [...tableValue[i]]

                this.setState({ indexAddRow: false })
            }
            else if (radioValue && radioValue === "Worked Flat") {
                console.log('mansi 123 => ', 123);

                // if (tableValue && tableValue.length > 1) {
                //     var copy_value = [...tableValue[i ]]

                // }
                // var copy_value = [...tableValue[i]]

                // this.setState({ indexAddRow: true })
                if (i % 2 === 0) {

                    var copy_value = [...tableValue[i + 1]]
                    this.setState({ indexAddRow: true })
                }
                else {

                    var copy_value = [...tableValue[i - 1]]
                    this.setState({ indexAddRow: true })
                }
            }
        if (type === undefined) {
            let stackArr = undoRedoStack

            stackArr.push({ index: [i], value: { ...copy_value }, key: "addRow" })
            this.setState({ undoRedoStack: [...stackArr] })
        }
        const insert = (arr, index, newItem) => [

            ...arr.slice(0, index),
            newItem,
            ...arr.slice(index)
        ]

        // if (datas && datas.construction === "Worked Flat") {
        //     if (i % 2 === 0) {

        //         var result = await insert([...tableValue], i + 1, copy_value)
        //     } else {

        //         var result = await insert([...tableValue], i + 1, copy_value)
        //     }
        // }

        if (datas && datas.construction === "Worked Flat" && this.state.indexAddRow === true) {

            var result = await insert([...tableValue], i + 1, copy_value)
            this.setState({ indexAddRow: false })
        }

        else
            if (datas && datas.construction) {
                if (datas && datas.construction === "Worked Flat") {

                    if (i % 2 === 0) {
                        var result = await insert([...tableValue], i + 1, copy_value)
                        this.setState({ indexAddRow: true })
                    }
                    else {
                        var result = await insert([...tableValue], i - 1, copy_value)
                        this.setState({ indexAddRow: true })
                    }

                }
            }
        // else if (radioValue && radioValue === "Worked Flat") {

        //     var result = await insert([...tableValue], i + 1, copy_value)
        // }
        // else {
        //     var result = await insert([...tableValue], i, copy_value)
        // }

        if (radioValue === "Worked Flat" && this.state.indexAddRow === true) {

            var result = await insert([...tableValue], i + 1, copy_value)
            this.setState({ indexAddRow: false })
        }

        else
            if (radioValue) {
                if (radioValue === "Worked Flat") {

                    if (i % 2 === 0) {
                        var result = await insert([...tableValue], i + 1, copy_value)
                        this.setState({ indexAddRow: true })
                    }
                    else {
                        var result = await insert([...tableValue], i - 1, copy_value)
                        this.setState({ indexAddRow: true })
                    }

                }
            }

        datas.rows = datas.rows + 1;

        function compare(a, b) {
            const bandA = b.index
            const bandB = a.index

            let comparison = 0;
            if (bandB > bandA) {
                comparison = 1;
            } else if (bandB < bandA) {
                comparison = -1;
            }
            return comparison;
        }



        arrayInstruction.sort(compare);


        this.setState({ instructionValues: [...arrayInstruction], editInstructionData: [...arrayInstruction], tableValue: result, datas: datas, rowUpdated: true })


        if (datas.rows % 2 === 0) {
            this.setState({ IsNumber: "even" })
        }
        else {
            this.setState({ IsNumber: "odd" })
        }
        setTimeout(() => {
            this.setState({ rowUpdated: false })
        }, 100)
        this.setState({ showMenu: false, menuIndex: null, checkVal: true })

    };

    addColumn = async (e, j) => {
        e.preventDefault();
        let { edit, tableValue, datas, undoRedoStack } = this.state;
        var arr = [];
        for (let i = 0; i < tableValue.length; i++) {

            for (let col = tableValue[i].length; col > j; col--) {

                tableValue[i][col] = tableValue[i][col - 1]
            }

        }
        let stackArr = [...undoRedoStack]

        stackArr.push({ index: [j], value: "", key: "addColumn" })
        datas.cells = datas.cells + 1;
        this.setState({ ...tableValue, datas, undoRedoStack: [...stackArr], rowUpdated: true })
        setTimeout(() => {
            this.setState({ rowUpdated: false })
        }, 100)
        this.setState({ showMenuforCol: false, menuIndex: null, checkVal: true })
    };


    deleteColumn = async (e, j) => {
        let { tableValue, datas, undoRedoStack } = this.state;
        var arr = [];
        for (let i = 0; i < tableValue.length; i++) {

            var temp = [...tableValue[i]]
            var data = temp.splice(j, 1)
            arr.push(temp)
        }
        let stackArr = [...undoRedoStack]

        stackArr.push({ index: [j], value: arr, key: "deleteColumn" })
        datas.cells = datas.cells - 1;
        this.setState({ tableValue: arr, datas, undoRedoStack: [...stackArr], rowUpdated: true })
        setTimeout(() => {
            this.setState({ rowUpdated: false })
        }, 100)
        this.setState({ showMenuforCol: false, menuIndex: null, checkVal: true })
    }

    handleInstructionOpen = (e) => {
        e.preventDefault();
        const newState = {}
        let { editData } = this.props;
        if (this.state.displayAllTextBox === false) {

            newState[`type`] = "all"
            newState[`displayAllTextBox`] = true
            newState[`displayTextBox`] = true

        }
        else {
            this.setState({
                type: "all", displayAllTextBox: false,
            })
            newState[`type`] = "all"
            newState[`displayAllTextBox`] = false
            newState[`displayTextBox`] = false
        }
        this.setState(newState)
        setTimeout(() => {
            this.scrollToXY('dragscroll', editData.data.rows * 41, editData.data.cells * 41);
        }, 1000000);

    };


    hadleSelectColor = (e, i) => {
        e.preventDefault()

        let { color } = this.state;

        let tempColor = color;
        tempColor[i] = e.target.value;
        this.setState({ color: tempColor })

    }

    handleClose = (e) => {
        e.preventDefault();
        this.setState({ displayColorPicker: false, ChangeColorPicker: false })
    };

    eImportantHandler = (e) => {
        // e.preventDefault();
        let { checkboxValue } = this.state;
        let tempval = { ...checkboxValue }
        tempval.important = e.importantValue;
        this.setState({ importantValue: e.importantValue, checkboxValue: tempval })
    };



    handleEditWithoutSave = (e) => {

        e.preventDefault();
        let { location, dispatch } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sizeId = path[3];
        let sectionId = path[4]
        this.props.form.validateFields((err, values) => {



            dispatch(getChart(patternId, sizeId, sectionId)).then(resp => {

                this.setState({ editInstructionData: resp.data.data && resp.data.data.instruction })

                if (resp.data && resp.data.data) {
                    this.setState({ defaulState: true, tableValue: resp.data.data.tableValue, datas: resp.data.data, visible: true, edit: true, editableSpin: true })
                }

            })


        })

    };

    handleEdit = (e) => {

        e.preventDefault();

        let { tableValue, color, instructionValues, radioValue, checkVal, instructionGroup } = this.state;
        let { location, dispatch } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sizeId = path[3];
        let sectionId = path[4]

        if (checkVal === true) {
            this.props.form.validateFields((err, values) => {

                dispatch(addChart(values, tableValue, instructionValues, instructionGroup, patternId, sizeId, sectionId, radioValue)).then(response => {
                    if (response && response.status === 200) {
                        if (checkVal === true) {
                            message.success('Chart saved successfully');
                        }
                        // window.location.reload();
                        dispatch(getChart(patternId, sizeId, sectionId)).then(resp => {

                            if (resp.data && resp.data.data) {
                                this.setState({ defaulState: true, tableValue: resp.data.data.tableValue, datas: resp.data.data, visible: true, edit: true, editableSpin: true })
                            }

                        })
                    }
                });
            })
        }

        this.setState({ defaulState: true, visible: true, edit: true, editableSpin: true })




    };

    handleEnterNote = (e) => {
        // e.preventDefault();
        if (e.which === 13) {
            this.handleNote(e);
        }
    }
    handleNumberEnter = (e) => {
        // e.preventDefault();
        if (e.which === 13) {
            this.handleNumber(e);
        }
    }

    handleOpenTextArea = () => {
        this.setState({ openTextArea: true })
    }

    handleStitchNumber = (e) => {
        if (this.state.stitchValue) {

            e.preventDefault();
            let { checkboxValue } = this.state;
            let tempval = { ...checkboxValue }
            tempval.numberValue = e.target.checked;
            this.setState({ stichNumberChecked: e.target.checked, checkboxValue: tempval })
        }
        else {
            message.error("Please select atleast one stitch")
        }
    }

    handleNumber = (e, i) => {
        e.preventDefault();
        let { tableValue, stitchNumberIndex, undoRedoStack } = this.state

        let row = stitchNumberIndex[0]
        let col = stitchNumberIndex[1]
        this.props.form.validateFields((err, values) => {
            let temp = tableValue;
            temp[row][col].numberVal = values.number
            let stackArr = undoRedoStack

            stackArr.push({ index: [row, col], value: { ...temp[row][col] } })

            this.setState({ numbersValue: values.number, stitchNumberIndex: [row, col], undoRedoStack: stackArr, tableValue: temp, numberModal: false })

            // this.props.form.resetFields();

        })


    }

    redoClick = (e, type) => {
        e.preventDefault();

        let { undoRedoStack, redoStack, tableValue } = this.state;
        let tempArr = [...tableValue];

        if (redoStack.length > 0) {

            if (redoStack && redoStack[redoStack.length - 1] && redoStack[redoStack.length - 1].key === "addColumn") {

                this.addColumn(e, redoStack[redoStack.length - 1].index)
                var redoStackData = (redoStack.pop());
                let tempRedo = redoStack.pop();

                const newUndoRedoStack = [...undoRedoStack];
                const arr = [...newUndoRedoStack, { ...(tempRedo) }];
                this.setState({
                    rowUpdated: true
                })
                this.setState({ undoRedoStack: arr, tableValue: tempArr })
                setTimeout(() => {
                    this.setState({ rowUpdated: false })
                }, 12)
            }
            else if (redoStack && redoStack[redoStack.length - 1] && redoStack[redoStack.length - 1].key === "deleteColumn") {

                this.deleteColumn(e, redoStack[redoStack.length - 1].index, "undo")
                var redoStackData = (redoStack.pop());
                let tempRedo = redoStack.pop();

                const newUndoRedoStack = [...undoRedoStack];
                var arr = [...newUndoRedoStack, { ...(tempRedo) }];
                this.setState({
                    rowUpdated: true
                })
                if (type === undefined) {

                    this.setState({ undoRedoStack: arr, tableValue: tempArr })
                }
                setTimeout(() => {
                    this.setState({ rowUpdated: false })
                }, 12)
            }
            else if (redoStack && redoStack[redoStack.length - 1] && redoStack[redoStack.length - 1].key === "addRow") {

                this.deleteRow(e, redoStack[redoStack.length - 1].index, "redo")
                var tempRedo = redoStack.pop();

                var newUndoRedoStack = [...undoRedoStack];
                if (type === undefined) {

                    var arr = [...newUndoRedoStack, { ...(tempRedo) }];
                }
                this.setState({
                    rowUpdated: true
                })
                this.setState({ undoRedoStack: arr, tableValue: tempArr })
                setTimeout(() => {
                    this.setState({ rowUpdated: false })
                }, 12)
            }
            else if (redoStack && redoStack[redoStack.length - 1] && redoStack[redoStack.length - 1].key === "deleteRow") {
                this.addRow(e, redoStack[redoStack.length - 1].index, "redo")
                let tempRedo = redoStack.pop();

                const newUndoRedoStack = [...undoRedoStack];
                const arr = [...newUndoRedoStack, { ...(tempRedo) }];
                this.setState({
                    rowUpdated: true
                })
                this.setState({ undoRedoStack: arr, tableValue: tempArr })
                setTimeout(() => {
                    this.setState({ rowUpdated: false })
                }, 12)

            }
            else {
                let tempRedo = redoStack.pop();

                const newUndoRedoStack = [...undoRedoStack];
                const arr = [...newUndoRedoStack, { ...(tempRedo) }];
                this.setState({
                    tableValue: [...tempArr],
                    rowUpdated: true
                })
                arr.map((v, ai, a) => {
                    this.setValueOnGrid(tempArr, { v, ai, a });
                    return v;
                });

                this.setState({ undoRedoStack: arr, tableValue: [...tempArr] })
                setTimeout(() => {
                    this.setState({ rowUpdated: false })
                }, 12)
            }
        }
    }



    setValueOnGrid = (array, { v, ai, a }) => {

        const value = v.value;
        const i = v.index[0];
        const j = v.index[1];

        array[i][j] = value;

        if (array[i][j].width > 0) {

            const next = array[i][j].width > 1 && j > a.length ? Array.from(Array(v.value.width - 1), (x, k) => k + j + 1) : [];

            for (const key of next) {


                array[i][key] = {
                    _id: '',
                    colorIndex: '',
                    border: '',
                    important: false,
                    note: array[i][key] && array[i][key].note ? array[i][key].note : ""
                };
            }
            for (let index = j - 1; index >= 0; index--) {
                if (array[i][index]._id !== '' && array[i][index].width + index > j) {
                    array[i][index] = {
                        _id: '',
                        colorIndex: '',
                        border: '',
                        important: false,
                        note: array[i][index] && array[i][index].note ? array[i][index].note : ""
                    };
                    return;
                } else {
                }
            }
        }

    }


    undoClick = async (e) => {
        e.preventDefault();
        let { location, dispatch } = this.props;
        let path = location.pathname.split('/')

        let patternId = path[2];
        let sizeId = path[3];
        let sectionId = path[4];

        let { undoRedoStack, tableValue, redoStack, loadStitch, editDatass } = this.state;
        let arr;
        if (undoRedoStack && undoRedoStack.length > 0) {

            if (undoRedoStack && undoRedoStack[undoRedoStack.length - 1] && undoRedoStack[undoRedoStack.length - 1].key === "addColumn") {

                this.deleteColumn(e, undoRedoStack[undoRedoStack.length - 1].index)
                var redoStackData = (undoRedoStack.pop());
                arr = undoRedoStack;
            }
            else if (undoRedoStack && undoRedoStack[undoRedoStack.length - 1] && undoRedoStack[undoRedoStack.length - 1].key === "deleteColumn") {

                this.addColumn(e, undoRedoStack[undoRedoStack.length - 1].index)
                var redoStackData = (undoRedoStack.pop());
                arr = undoRedoStack;
            }
            else if (undoRedoStack && undoRedoStack[undoRedoStack.length - 1] && undoRedoStack[undoRedoStack.length - 1].key === "addRow") {

                this.deleteRow(e, undoRedoStack[undoRedoStack.length - 1].index, "undo")
                var redoStackData = (undoRedoStack.pop());
                arr = undoRedoStack;
            }
            else if (undoRedoStack && undoRedoStack[undoRedoStack.length - 1] && undoRedoStack[undoRedoStack.length - 1].key === "deleteRow") {

                this.addRow(e, undoRedoStack[undoRedoStack.length - 1].index)
                var redoStackData = (undoRedoStack.pop());
                arr = undoRedoStack.pop();

            }

            else {
                if (undoRedoStack.length > 0) {


                    let { editData } = this.props;
                    var redoStackData = (undoRedoStack.pop());

                    arr = undoRedoStack;
                    var tempArr = [...tableValue];

                    // await dispatch(getChart(patternId, sizeId, sectionId)).then(resp => {
                    //     this.setState({ editInstructionData: resp.data.data && resp.data.data.instruction })
                    // var oldData = resp && resp.data && resp.data.data && resp.data.data.tableValue && resp.data.data.tableValue && resp.data.data.tableValue[redoStackData.index[0]][redoStackData.index[1]]


                    // if ((oldData && oldData._id === "") && (oldData && oldData.colorIndex && oldData.colorIndex === "") && (oldData && oldData.border && oldData.border === "")) {

                    tempArr[redoStackData.index[0]][redoStackData.index[1]] = {
                        _id: '',
                        colorIndex: '',
                        border: '',
                        important: false,
                        note: tempArr[redoStackData.index[0]][redoStackData.index[1]].note ?
                            tempArr[redoStackData.index[0]][redoStackData.index[1]].note : ""
                    };

                    // }
                    // else {

                    //     tempArr[redoStackData.index[0]][redoStackData.index[1]] = { ...oldData }

                    // }
                }

                // })
                this.setState({ rowsindex: redoStackData.index[0], cellsindex: redoStackData.index[1] })

                arr.map((v, ai, a) => {
                    this.setValueOnGrid(tempArr, { v, ai, a });
                    return v;
                });
                this.setState({
                    tableValue: [...tempArr],
                })

            }
            this.setState({
                rowUpdated: true,
                undoRedoStack: arr,
                redoStack: [
                    ...redoStack,
                    redoStackData
                ],

                // rowUpdated: true,
                // tableValue: tempArr
            })


            this.setState({ loadStitch: true })

            setTimeout(() => {

                this.setState({ rowUpdated: false, loadStitch: false })
            }, 12)

        }

    }


    handleTableChange = (e, row, col) => {
        e.preventDefault();
        this.setState({ checkVal: true, tableCheck: true, changedRowIndex: row })
        let { editData } = this.props;

        let { checkboxValue, numberValue, numbersValue, numberVal, undoRedoStack, tableValue, stitchValue, boderValue, markValue, edit, importantValue, colorValue, color, colorIndex } = this.state;


        let stackArr = undoRedoStack


        const newState = {}
        var current = checkboxValue;
        let totalCol = (tableValue[0].length)
        let remainingCol = totalCol - col
        const tempArr = [...tableValue];


        if (checkboxValue && checkboxValue.stitchData && checkboxValue.stitchData.width > remainingCol) {
            message.destroy();
            message.error("Please check the width")
            newState[`tableCheck`] = false
        } else {
            if (checkboxValue && checkboxValue.stitchData && checkboxValue.stitchData.width != null) {

                for (let i = col; i >= 0; i--) {
                    var prev = { ...tableValue[row][i] };

                    if ((Number(prev.width) + i) > col) {

                        var values = {
                            _id: '',
                            colorIndex: '',
                            border: '',
                            // important: tableValue[row][i].important ? tableValue[row][i].important : false,
                            important: false,
                            note: tableValue[row][i].note ? tableValue[row][i].note : ""
                        }
                        tableValue[row][i] = values;
                        break;
                    }
                }


                for (let i = col; i < (Number(current.stitchData.width) + col); i++) {
                    let values = {
                        _id: '',
                        note: tableValue[row][i].note ? tableValue[row][i].note : "",
                        colorIndex: '',
                        border: '',
                        // important: tableValue[row][i].important ? tableValue[row][i].important : false
                        important: false
                    }
                    tableValue[row][i] = values;
                }
            }


            if (e.target.checked === true) {
                if (tableValue && tableValue[row][col] && tableValue[row][col].numberVal) {

                    let val = tempArr[row][col]
                    let tempData = { ...val, numberVal: tempArr[row][col].numberVal, }
                    tempArr[row][col] = tempData
                    newState[`tableValue`] = [...tempArr]

                    newState[`stitchNumberIndex`] = [row, col]
                    newState[`numberModal`] = true
                    this.setState({ newState })

                }

                if (tempArr[row][col].important === true) {
                    let val = tempArr[row][col]
                    let tempData = { ...val, impotant: true, }
                    tempArr[row][col] = tempData
                    newState[`tableValue`] = [...tempArr]
                    newState[`importantNoteIndex`] = [row, col]
                    newState[`importantModal`] = true
                }

                if (boderValue) {
                    let val = tempArr[row][col]
                    let tempData = { ...val, border: boderValue, }
                    tempArr[row][col] = tempData
                    newState[`tableValue`] = [...tempArr]

                }
                if (markValue) {

                    let val = tempArr[row][col]
                    let tempData = { ...val, mark: markValue, }
                    tempArr[row][col] = tempData
                    newState[`tableValue`] = [...tempArr]
                }
                if (importantValue) {

                    let val = tempArr[row][col]
                    let tempData = { ...val, important: importantValue, }
                    tempArr[row][col] = tempData
                    newState[`tableValue`] = [...tempArr]

                    newState[`tableValue`] = tempArr
                    newState[`importantNoteIndex`] = [row, col]
                    newState[`importantModal`] = true


                }
                if (numbersValue && this.state.stichNumberChecked === true) {

                    let val = tempArr[row][col]
                    let tempData = { ...val, numberVal: numbersValue }
                    tempArr[row][col] = tempData
                    newState[`tableValue`] = [...tempArr]

                    newState[`tableValue`] = tempArr
                    newState[`stitchNumberIndex`] = [row, col]
                    newState[`numberModal`] = true


                }


                if (stitchValue) {

                    let val = tempArr[row][col]
                    let tempData = { ...val, ...(checkboxValue.stitchData ? checkboxValue.stitchData : {}) }
                    tempArr[row][col] = tempData
                    newState[`tableValue`] = tempArr
                    newState[`tableCheck`] = false

                }

                if (colorValue) {
                    let val = tempArr[row][col]
                    let tempData = { ...val, color: colorValue, colorIndex: colorIndex }
                    tempArr[row][col] = tempData
                    newState[`tableValue`] = [...tempArr]
                }

                if (this.state.stichNumberChecked === true) {

                    newState[`stitchNumberIndex`] = [row, col]
                    newState[`numberModal`] = true



                }
                else {
                    stackArr.push({ index: [row, col], value: { ...tempArr[row][col] } })

                }
            }
        }



        newState[`undoRedoStack`] = stackArr

        newState[`tableCheck`] = false
        this.setState({ ...newState })
        // if (this.state.numberModal === true) {
        //     var x = document.getElementById("textautofocus").autofocus;
        //     console.log('x => ', x);

        // }
    }


    makeGrid = (cols, rows) => (
        <div className="grid-table-div">
            <div className="GridBorder"></div>

            <div border="1" className="grid-table" >
                {
                    this.state.tableValue.map((row, i) => {
                        return (
                            <div key={i} className="grid-table-row">
                                {/* <span>{this.state.tableValue.length - i}</span> */}
                                <Row
                                    key={`row-${i}`}
                                    rowChanged={this.state.changedRowIndex === i || this.state.rowUpdated}
                                    rowIndex={i}
                                    loadStitch={this.state.loadStitch}
                                    rowsindex={this.state.rowsindex}
                                    cellsindex={this.state.cellsindex}
                                    cells={row}
                                    stitchLst={this.state.stitchLst}
                                    color={this.state.color}
                                    isChecked={this.state.tableCheck}
                                    onCellChange={this.handleTableChange}
                                ></Row>
                            </div>
                        )
                    })
                }
            </div>
            <div className="GridBorderBottom"></div>
        </div>
    )

    myFunction(e, i) {
        e.preventDefault();
        this.setState({ showMenu: true, showMenuforCol: false, menuIndex: i })
    }

    myColfunc(e, i) {
        e.preventDefault();
        this.setState({ showMenuforCol: true, showMenu: false, menuIndex: i })
    }



    render() {
        const { editData, addChartLoading, gridArrayLoading } = this.props;
        const { getFieldDecorator } = this.props.form;
        let { datas, radioValue, stitchNumberIndex, radioRequired, undoRedoStack, editInstructionData, instructionValues, importantNoteIndex, checkVal, tableValue, edit, color, cellReuired, rowsReuired, type, displayTextBox, patternId, sizeId, patternName, sizeName, sectionName, IsNumber } = this.state

        return (
            <div>
                {
                    checkVal === true &&
                    <Beforeunload
                        onBeforeunload=
                        {
                            () => "You have unsaved changes. Click Cancel to return to your chart, or click OK to abandon your changes"
                        }
                    />
                }

                <Prompt
                    when={checkVal === true}
                    message='You have unsaved changes. Click Cancel to return to your chart, or click OK to abandon your changes'
                />
                <Layout>
                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">
                            <Breadcrumb.Item ><Link to={adminRoutes.PATTERNLIST}>{patternName && patternName.patternName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={adminRoutes.SIZELIST + "/" + patternId}>{sizeName && sizeName.sizeName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={adminRoutes.SECTIONLIST + "/" + patternId + "/" + sizeId}>{sectionName && sectionName.sectionName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>Chart</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="HideShowDIV">
                        <button onClick={this.hideShow} >
                            <span className="HideSpan">Hide Stitch</span>
                            <span className="ShowSpan">Show Stitch</span> 
                        </button>
                    </div>
                    <div className="grid-wrap">
                        <div className="grid-left">
                            <div className="StitchBox d-flex">
                                <div className="grid-box-wrap ElementalStitch">
                                    <ElementalStitch eStitchData={this.eStitchDataHandler} stitchData={this.state.stitchValue} />
                                </div>
                                <div className="grid-box-wrap CompoundStitch">
                                    <CompoundStitch eStitchData={this.eStitchDataHandler} stitchData={this.state.stitchValue} />
                                </div>
                            </div>
                            <div className="StitchOption d-flex">
                                <Border eBorder={this.eBorderHandler} boderValue={this.state.boderValue}></Border>
                                <Marker eBorder={this.eMarkHandler} markValue={this.state.markValue} ></Marker>
                                <div className="StitchNumber grid-box-wrap repeat-div">
                                    <h2>Stitch Number</h2>
                                    <Checkbox checked={this.state.stichNumberChecked ? true : false} defaultChecked={false}
                                        onChange={this.handleStitchNumber}></Checkbox>

                                </div>
                                <div className="grid-box-wrap grid-colors">
                                    <h2>Colors</h2>
                                    <div className="d-flex color-wrap">
                                        {

                                            color && color.length > 0 && color.map((value, i) => (
                                                <div className="color-div" key={`color-${i}`}>

                                                    <Checkbox checked={this.state.colorValue !== null && this.state.colorIndex === value._id ? true : false} onChange={(e) => this.handleColorClick(e, value, i)}>
                                                        <Popover trigger="hover" content={<div><h3>{value && value.text}</h3></div>} >
                                                            <span className="colorSection" style={{ backgroundColor: value.color }}></span>

                                                        </Popover>
                                                    </Checkbox>

                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="important-div">
                                    <Important eImportant={this.eImportantHandler} importantValue={this.state.importantValue}></Important>
                                </div>
                            </div>
                        </div>

                        <div className="grid-right">
                            <div className="grid-right-title d-flex">
                                <h2>Chart View</h2>
                                <div className="stitches-rotation d-flex">
                                    <button className="left-rotation" onClick={(e) => this.undoClick(e)}><span>Undo</span></button>
                                    <button className="right-rotation" onClick={(e) => this.redoClick(e)}><span>Redo</span></button>

                                    <button className="close-rotation" onClick={this.handleResetCheckboxValue}><span>Clear</span></button>
                                </div>
                            </div>
                            <div className="d-flex GridBoxWrap">
                                <div className="grid-right-box">
                                    <div className="grid-right-head d-flex">
                                        <div className="grid-right-head-left d-flex">
                                            <strong>Rows : <span>{edit === true ? editData && editData.data && editData.data.rows : datas && datas.rows ? datas.rows : 0}</span></strong>
                                            <strong>Columns : <span>{edit === true ? editData && editData.data && editData.data.cells : datas && datas.cells ? datas.cells : 0}</span></strong>
                                            <strong>Construction : <span>{radioValue ? radioValue : edit === true ? editData && editData.data && editData.data.construction : radioValue}</span></strong>
                                        </div>


                                        <div className="gridDelete">
                                            <Popconfirm
                                                title="Are you sure you want to delete this chart?"
                                                onConfirm={(e) => this.handleChartDelete(e)}
                                                onCancel={this.cancel}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button>
                                                    <img src="images/trash.svg" alt="" />
                                                    Delete
                                                </Button>
                                            </Popconfirm>
                                        </div>

                                        <div className="grid-edit">
                                            {checkVal === true ? <Popconfirm
                                                title="Please make sure you have saved your changes before edit"
                                                onConfirm={(e) => this.handleEdit(e)}
                                                onCancel={(e) => this.handleEdit(e)}
                                                okText="Save"
                                                cancelText="No"
                                            >
                                                <Button>
                                                    <img src="images/edit.svg" alt="" onClick={(e) => this.handleEdit(e)} />
                                                        Edit
                                                </Button>
                                            </Popconfirm>
                                                :
                                                <Button onClick={(e) => this.handleEdit(e)}>
                                                    <img src="images/edit.svg" onClick={(e) => this.handleEdit(e)} alt="" />
                                                    Edit
                                                </Button>
                                            }
                                        </div>
                                        <div className="AddMultiline">
                                            <Button onClick={this.handleOpenTextArea}>Add Multiline</Button>
                                        </div>
                                        {this.state.openTextArea === true && <Modal visible={this.state.openTextArea} onCancel={() => this.setState({ openTextArea: false })} >
                                            <Form.Item >
                                                <label>Description</label>
                                                {getFieldDecorator('instructionData', { initialValue: this.state.instructions ? this.state.instructions : editData && editData.data ? editData.data.instructionGroup : datas && datas.instructionGroup ? datas && datas.instructionGroup : "" }, {
                                                })
                                                    (
                                                        <TextArea
                                                            className="text-area-input"
                                                            type="text"
                                                            placeholder="Add your multi-line instruction here"
                                                        ></TextArea>
                                                    )}
                                            </Form.Item>
                                            <FormItem>
                                                <div className="popup-btn">
                                                    <button onClick={(e) => this.handlemultilineInstruction(e)} className="ant-btn OrangeButton"><span>Ok</span></button>
                                                </div>
                                            </FormItem>
                                        </Modal>}
                                        <div className="grid-right-head-right d-flex">

                                            {addChartLoading === true && <Spin />}
                                            {gridArrayLoading === true && <Spin />}

                                            <div className="grid-nav">

                                                <Button onClick={(e) => this.handleInstructionOpen(e)}>View Instructions<img src="images/nav-icon-01.svg" alt="" /></Button></div>

                                        </div>
                                    </div>

                                    <div className={`${"dragscroll grid-right-grid"} ${this.state.displayTextBox === true || this.state.displayAllTextBox === true ? "instructionWidth" : ""}`} id="Body">
                                        <div className="HorizonCounter d-flex">
                                            {tableValue.length > 0 && tableValue[0].map((v, j, a) => {
                                                return (
                                                    <div className="HorizonCounterDIV">
                                                        <Button onClick={(e) => this.myColfunc(e, j)} >
                                                            <span key={j}>{a.length - j}</span>
                                                        </Button>

                                                        {
                                                            this.state.showMenuforCol === true && this.state.menuIndex === j && <ul id="myDIV" >
                                                                <li><button onClick={(e) => this.addColumn(e, j)}>Insert Above</button></li>
                                                                <li><button onClick={(e) => this.addColumn(e, j)}>Insert below</button></li>
                                                                <li><button onClick={(e) => this.deleteColumn(e, j)}> Delete Row</button></li>
                                                            </ul>
                                                        }
                                                    </div>

                                                )
                                            })}
                                        </div>
                                        <div className="gridRightGridInnerWrap">
                                            <div className="d-flex gridRightGridInner">
                                                {radioValue && radioValue === "In the Round" && <div className={`${IsNumber === "even" ? "odd" : "odd"} ${"GridRightCounterLeftWrap BoxLeftBorder "} ${"InRoundLeft"}`}>
                                                    {tableValue.map((row, i) => (
                                                        <div key={i} className="GridRightCounter-left">{this.state.tableValue.length - i}</div>
                                                    ))}
                                                </div>
                                                }
                                                {radioValue && radioValue === "Worked Flat" && <div className={`${IsNumber === "even" ? "even" : "odd"} ${"GridRightCounterLeftWrap BoxLeftBorder "}`}>


                                                    {radioValue && radioValue === "Worked Flat" ? <div className="GridRightCounterLeftWrap">

                                                        {tableValue.map((row, i) => {
                                                            if ((i) % 2 !== 0) {
                                                                return (

                                                                    <div key={i} className="GridRightCounter-left">
                                                                        <Button onClick={(e) => this.myFunction(e, i)} >

                                                                            {(tableValue.length % 2 === 0) ?
                                                                                `${this.state.tableValue.length - i + 1}` :
                                                                                `${this.state.tableValue.length - i}`}
                                                                        </Button>
                                                                        {this.state.showMenu === true && this.state.menuIndex === i && <ul id="myDIV">
                                                                            <li><button onClick={(e) => this.addRow(e, i)}>Insert Above</button></li>
                                                                            <li><button onClick={(e) => this.addRow(e, i)}>Insert below</button></li>
                                                                            <li><button onClick={(e) => this.deleteRow(e, i)}> Delete Row</button></li>
                                                                        </ul>}
                                                                    </div>
                                                                )
                                                            }
                                                        })}


                                                    </div>

                                                        :
                                                        ""
                                                    }
                                                </div>
                                                }


                                                {gridArrayLoading === false && this.makeGrid()}

                                                {radioValue && radioValue === "Worked Flat" ?

                                                    <div className={`${IsNumber === "even" ? "even" : "odd"} ${"BoxRightBorder GridRightCounterRightWrap"}`}>
                                                        {(tableValue.map((row, i) => {
                                                            if ((i) % 2 === 0) {
                                                                return (
                                                                    <div key={i} className="GridRightCounter-right" >
                                                                        <Button onClick={(e) => this.myFunction(e, i)} >
                                                                            {(tableValue.length % 2 !== 0) ? this.state.tableValue.length - i : this.state.tableValue.length - i - 1}
                                                                        </Button>

                                                                        {this.state.showMenu === true && this.state.menuIndex === i && <ul id="myDIV">
                                                                            <li><button onClick={(e) => this.addRow(e, i)}>Insert Above</button></li>
                                                                            <li><button onClick={(e) => this.addRow(e, i)}>Insert below</button></li>
                                                                            <li><button onClick={(e) => this.deleteRow(e, i)}> Delete Row</button></li>
                                                                        </ul>}

                                                                    </div>
                                                                )

                                                            }
                                                        }))}



                                                    </div>

                                                    :
                                                    <div className="GridRightCounter BoxRightBorder">
                                                        {tableValue.map((row, i) => (
                                                            <div key={i} className="GridRightCounter-row">
                                                                <Button onClick={(e) => this.myFunction(e, i)} >
                                                                    <span>{this.state.tableValue.length - i}</span>
                                                                </Button>
                                                                {this.state.showMenu === true && this.state.menuIndex === i && <ul id="myDIV">
                                                                    <li><button onClick={(e) => this.addRow(e, i)}>Insert Above</button></li>
                                                                    <li><button onClick={(e) => this.addRow(e, i)}>Insert below</button></li>
                                                                    <li><button onClick={(e) => this.deleteRow(e, i)}> Delete Row</button></li>
                                                                </ul>}
                                                            </div>
                                                        ))}
                                                    </div>
                                                }

                                                <div className="instruction-div" >

                                                    {

                                                        tableValue.map((row, i) => (
                                                            <div key={`instruction-${i}`}>

                                                                {type === "single" ?


                                                                    displayTextBox === true &&
                                                                        this.state.indexVAl === i
                                                                        ?
                                                                        <Input
                                                                            autoFocus
                                                                            className="grid-instruction-open"
                                                                            onChange={(e) => this.handleInstructionData(e, i)} name="" placeholder="" value={

                                                                                editInstructionData ?
                                                                                    editInstructionData && editInstructionData.length > 0 && editInstructionData.findIndex(v => v.index === i) >= 0 ? editInstructionData[editInstructionData.findIndex(v => v.index === i)].text : ""
                                                                                    : this.state.instructionValues[i] ? this.state.instructionValues[i].text : ""} />

                                                                        :
                                                                        <Popover trigger="hover" content={<div >{editInstructionData ?
                                                                            editInstructionData && editInstructionData.length > 0 && editInstructionData.findIndex(v => v.index === i) >= 0 ? editInstructionData[editInstructionData.findIndex(v => v.index === i)].text : ""
                                                                            : this.state.instructionValues[i] ? this.state.instructionValues[i].text : ""} </div>}>
                                                                            <Button onClick={(e) => this.handleInstructionText(e, i)}>
                                                                            </Button>
                                                                        </Popover>

                                                                    :
                                                                    type === "all" && this.state.displayAllTextBox === true ? <Input
                                                                        className="grid-instruction-open"
                                                                        onChange={(e) => this.handleInstructionData(e, i)} name="" placeholder="" value=
                                                                        {editInstructionData ?
                                                                            editInstructionData.length > 0 && editInstructionData.findIndex(v => v.index === i) >= 0 ? editInstructionData[editInstructionData.findIndex(v => v.index === i)].text : ""
                                                                            : this.state.instructionValues[i] ? this.state.instructionValues[i].text : ""} />
                                                                        :
                                                                        <Popover trigger="hover" content={editInstructionData === true ?
                                                                            editInstructionData.instruction.length > 0 && editInstructionData.findIndex(v => v.index === i) >= 0 ? editInstructionData[editInstructionData.findIndex(v => v.index === i)].text : ""
                                                                            : this.state.instructionValues[i] ? this.state.instructionValues[i].text : ""}>
                                                                            <Button onClick={(e) => this.handleInstructionText(e, i)}>
                                                                            </Button>
                                                                        </Popover>
                                                                }
                                                                {/* <button onClick={(e) => this.deleteRow(e, i)}>Delete</button>
                                                                <button onClick={(e) => this.addRow(e, i)}>Add</button> */}

                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="tools">
                                        <button className="zoom-minus" onClick={(e) => this.zoomOut(e)}></button>
                                        <button className="zoom-line" ><span></span></button>
                                        <button className="zoom-plus" onClick={(e) => this.zoomIn(e)}></button>
                                    </div>
                                    <div className="btm-btn chat-view-btm d-flex">
                                        <Form.Item>
                                            <div className="ChatExitButton">
                                                {/* <Popconfirm
                                                    title="Are you sure you want to remove the changes?"
                                                    onConfirm=
                                                    onCancel={this.cancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                > */}
                                                <Button type="button" onClick={this.handleExit}>Exit</Button>
                                                {/* </Popconfirm> */}
                                            </div>

                                        </Form.Item>
                                        <Form.Item>
                                            <div>
                                                {!addChartLoading && <Button className="OrangeButton" type="primary" onClick={(e) => this.handleGridSubmit(e)}>Save</Button>}
                                                {addChartLoading && <Spin />}
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>

                                {/* <div className="instruction-div">
                                    {tableValue.map((row, i) => (
                                        <div>

                                            <div key={`instruction-${i}`}>

                                                {
                                                    displayTextBox === true && this.state.indexVAl === i
                                                        ? <Input
                                                            autoFocus
                                                            className="grid-instruction-open"
                                                            onChange={(e) => this.handleInstructionData(e, i)} name="" placeholder="" value={edit === true ?
                                                                editData && editData.data && editData.data.instruction[i].text
                                                                : this.state.instructionValues[i] ? this.state.instructionValues[i].text : ""} />
                                                        : <Button onClick={(e) => this.handleInstructionText(e, i)}></Button>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div> */}
                            </div>
                        </div >
                    </div >
                </Layout >
                <Modal visible={this.state.importantModal} onCancel={(e) => this.handleNotecross(e)}>

                    <Form.Item >
                        <label>Important :</label>
                        {getFieldDecorator('note', {
                            initialValue: importantNoteIndex.length > 0 && tableValue[importantNoteIndex[0]][importantNoteIndex[1]].note ? tableValue[importantNoteIndex[0]][importantNoteIndex[1]].note : ""
                        })(
                            <Input
                                type="text"
                                ref={this.refImportant}
                                // onLoad={e => console.log("textboxAUTO", e)}
                                autoFocus={true}
                                // id="textautofocus"
                                placeholder="What is important to know?"
                                onKeyDown={(e) => this.handleEnterNote(e)}
                            />,
                        )}
                    </Form.Item>


                    <Form.Item>
                        {/* <div className="popup-btn">
                            <button onClick={(e) => this.handleNoteDelete(e)} className="ant-btn ant-btn-primary"><span>Delete</span></button>

                            <button onClick={this.handleNote} className="ant-btn ant-btn-primary"><span>OK</span></button>
                        </div> */}
                        <div className="popup-btn">
                            <button onClick={(e) => this.handleNoteDelete(e)} className="ant-btn WhiteButton"><span>Delete</span></button>
                            <button onClick={this.handleNote} className="ant-btn OrangeButton"><span>Ok</span></button>
                        </div>
                    </Form.Item>
                </Modal>

                <Modal visible={this.state.numberModal} onCancel={() => this.setState({ numberModal: false })}>

                    <Form.Item >
                        <label>Number :</label>
                        {getFieldDecorator('number', {
                            initialValue: stitchNumberIndex && stitchNumberIndex.length > 0 && tableValue && tableValue[stitchNumberIndex[0]][stitchNumberIndex[1]].numberVal ? tableValue[stitchNumberIndex[0]][stitchNumberIndex[1]].numberVal : ""
                        })(
                            <Input
                                type="text"
                                ref={this.refNumber}
                                // onLoad={e => console.log("textboxAUTO", e)}
                                autoFocus={true}
                                placeholder="Add a number to a stitch?"
                                onKeyDown={(e) => this.handleNumberEnter(e)}
                            />,
                        )}
                    </Form.Item>


                    <Form.Item>
                        <div className="popup-btn">

                            <button onClick={this.handleNumber} className="ant-btn ant-btn-primary"><span>OK</span></button>
                        </div>
                        <div className="popup-btn">
                            {/* <button onClick={(e) => this.handleNoteDelete(e)} className="ant-btn WhiteButton"><span>Delete</span></button>
                            <button onClick={this.handleNote} className="ant-btn OrangeButton"><span>Ok</span></button> */}
                        </div>
                    </Form.Item>
                </Modal>

                {
                    (addChartLoading === false || edit !== true) && <Modal maskClosable={false} closable={this.state.edit === true ? true : false}


                        visible={this.state.visible} onOk={(e) => this.handleOk(e)} onCancel={() => this.setState({ visible: false })}>
                        <Form>
                            <div className="two-column d-flex">
                                {
                                    edit && edit === true ?
                                        <Form.Item >
                                            <label>Rows :</label>
                                            {getFieldDecorator('rows', { initialValue: editData && editData.data ? editData.data.rows : datas && datas.rows }, {
                                            })(
                                                <InputNumber
                                                    placeholder="Row"
                                                    readOnly
                                                    min={1}
                                                />,
                                            )}
                                            {rowsReuired === true && <label className="SelectColor">Please add row</label>}
                                        </Form.Item>
                                        : <Form.Item >
                                            <label>Rows :</label>
                                            {getFieldDecorator('rows', { initialValue: editData && editData.data ? editData.data.rows : datas && datas.rows }, {
                                            })(
                                                <InputNumber
                                                    placeholder="Row"

                                                    min={1}
                                                />,
                                            )}
                                            {rowsReuired === true && <label className="SelectColor">Please add row</label>}
                                        </Form.Item>
                                }
                                {
                                    edit && edit === true ?
                                        <Form.Item>
                                            <label>Columns :</label>
                                            {getFieldDecorator('cells', { initialValue: editData && editData.data ? editData.data.cells : datas && datas.cells }, {
                                            })(
                                                <InputNumber
                                                    type="number"
                                                    readOnly
                                                    placeholder="Column"
                                                    min={1}
                                                />,
                                            )}
                                            {cellReuired === true && <label className="SelectColor">Please add column</label>}

                                        </Form.Item>
                                        :
                                        <Form.Item>
                                            <label>Columns :</label>
                                            {getFieldDecorator('cells', { initialValue: editData && editData.data ? editData.data.cells : datas && datas.cells }, {
                                            })(
                                                <InputNumber
                                                    type="number"
                                                    placeholder="Column"
                                                    min={1}
                                                />,
                                            )}
                                            {cellReuired === true && <label className="SelectColor">Please add column</label>}

                                        </Form.Item>
                                }
                            </div>

                            <div className="construcation">
                                <Form.Item>
                                    <label>Construction</label>
                                    <Radio.Group className="d-flex" name="radiogroup" onChange={this.onChange} value={this.state.radioValue ? this.state.radioValue : edit === true ? editData && editData.data && editData.data.construction : ""}>
                                        <Radio value="In the Round" >In the Round</Radio>
                                        <Radio value="Worked Flat">Worked Flat</Radio>
                                        {radioRequired === true && <label className="SelectColor">Please select construction</label>}

                                    </Radio.Group>

                                </Form.Item>
                            </div>
                            <Form.Item>

                                <div className="popup-btn">
                                    <button onClick={(e) => this.handleCancel(e)} className="ant-btn WhiteButton"><span>Cancel</span></button>
                                    <button onClick={this.handleSubmit} className="ant-btn OrangeButton"><span>Save</span></button>
                                </div>
                            </Form.Item>
                        </Form>

                    </Modal>
                }
            </div >
        )
    }
}


const mapStateToProps = ((state) => {
    let { loading, data, error } = state.getStiches
    let { gridArrayLoading, gridArrayData } = state.createGridArray
    return {
        loading,
        data,
        error,
        editLoading: state.getChart.loading,
        editData: state.getChart.data,
        editError: state.getChart.error,
        colorLoading: state.getColors.loading,
        colorData: state.getColors.data,
        colorError: state.getColors.error,
        addChartLoading: state.addChart.loading,
        gridArrayLoading,
        gridArrayData
    }
})

const AddGrid = Form.create({ name: 'getStiches' })(Grid);
export default withRouter(connect(mapStateToProps)(AddGrid))